export const annotatorContentKey = 'annotatorContent';
export const annotationsKey = 'annotations';

export const categories = [
  // { labelName: 'Edit', color: '#3357FF', categoryId: 'edit' },
  // { labelName: 'Skip', color: '#FF33A1', categoryId: 'skip' },
  // { labelName: 'Update', color: '#33FFF5', categoryId: 'update' },
  // { labelName: 'Rewrite', color: '#33FF57', categoryId: 'rewrite' },
  // { labelName: 'Question', color: '#FFD133', categoryId: 'question' },
  { labelName: 'Added Question', color: '#ECEE63', categoryId: 'userQuestion' },
  // { labelName: 'Redundant', color: '#FF5733', categoryId: 'redundant' },
];

export const answersUploadCategories = [
  { labelName: 'Add Answer', color: '#ECEE63', categoryId: 'userQuestion' },
]
import { type FC, memo, useState, MouseEvent } from 'react';
import { Button, Box, IconButton, Collapse, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faArrowsRotate, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { compareObjects } from '../../utils';
import type { AnnotationItemProps } from "./interfaces";
import { FoundAnswers, FoundQuestion, StyledBox, StyledHeader, StyledText } from './components';

const AnnotationItem: FC<AnnotationItemProps> = ({ annotation, showAllAnswers = false, expanded = false, isLoading, index, openModal, onDelete, onEdit, getAnswers }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const onDeleteEvent = (event: MouseEvent<HTMLButtonElement>, id: string) => {
    event.stopPropagation();
    onDelete(id);
    openModal(null);
  };

  return (
    <StyledBox sx={{ background: annotation.color }} id={annotation.labelId} key={annotation.labelId}>
      {!expanded && (
        <Tooltip title="Expand in modal">
          <IconButton onClick={() => openModal(annotation)} sx={{ position: 'absolute', top: '5px', right: '5px' }}>
            <FontAwesomeIcon icon={faExpand} size="xs" color={'rgb(30, 48, 80)'} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Generate new answer">
        <IconButton onClick={() => getAnswers([annotation])} sx={{ position: 'absolute', top: '5px', right: '35px' }}>
          <FontAwesomeIcon icon={faArrowsRotate} className={isLoading ? 'fa-spin' : ''} size="xs" color={'rgb(30, 48, 80)'} />
        </IconButton>
      </Tooltip>
      <StyledHeader variant="h6" bgcolor={annotation.color}>
        {`${annotation.label} #${index + 1}`}
      </StyledHeader>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StyledHeader variant="body1" bgcolor={annotation.color}>
          Context:
        </StyledHeader>
        <IconButton onClick={toggleCollapse}>
          <FontAwesomeIcon size="xs" icon={isCollapsed ? faChevronDown : faChevronUp} />
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed} collapsedSize={200}>
        <StyledText variant="body1" bgcolor={annotation.color} sx={{ maxHeight: isCollapsed ? 200 : 'none', overflow: isCollapsed ? 'auto' : 'visible' }}>
          {annotation.text}
        </StyledText>
      </Collapse>
      {annotation.question?.length ? <FoundQuestion header="Found question:" annotation={annotation} onEdit={onEdit} /> : ''}
      {annotation.answers ? <FoundAnswers showAllAnswers={showAllAnswers} annotation={annotation} onEdit={onEdit} /> : ''}
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={(event) => onDeleteEvent(event, annotation.labelId)}>
          Delete
        </Button>
      </Box>
    </StyledBox>
  );
};


export default memo(AnnotationItem, (prevProps, nextProps) => compareObjects(prevProps.annotation, nextProps.annotation)
  && prevProps.expanded === nextProps.expanded
  && prevProps.isLoading === nextProps.isLoading
  && prevProps.onDelete === nextProps.onDelete
  && prevProps.getAnswers === nextProps.getAnswers
  && prevProps.onEdit === nextProps.onEdit
);


import { callMsGraph } from '../utils/MsGraphApiCall';
import { azureFunctionUrl } from "../constants";

export const getDocContents = async (item: any) => {
  const data = await callMsGraph(`https://graph.microsoft.com/v1.0/sites/${item.siteId}/drives/${item.driveId}/items/${item.sharepointIds.listItemUniqueId}/content`);
  return data;
}

export const apiGetPaginatedFilesList = async (searchString: string, page: number = 1, pageSize: number) => {
  const pageNumber = page * pageSize - pageSize;

  const data = await callMsGraph('https://graph.microsoft.com/v1.0/search/query', {
    method: 'POST',
    header: {
      contentType: 'application/json'
    },
    body: JSON.stringify({
      "requests": [
        {
          "entityTypes": ["driveItem"],
          "query": {
            "queryString": `(filetype:docx OR filetype:xlsx OR filetype:pdf) AND filename:${searchString}`
          },
          "size": 15,
          "from": pageNumber,
          "sortProperties": [{
            "name": "lastModifiedDateTime",
            "isDescending": true
          }]
        }
      ]
    })
  });

  return data;
}

export const getTemplatesList = async () => {
  const siteId = 'elekscloud.sharepoint.com,590df659-e094-47f2-9d12-3e91f9d91ba8,3cb871dc-70a6-407f-921f-5be39004ddba';
  const driveId = 'b!WfYNWZTg8kedEj6R-dkbqNxxuDymcH9Akh9b45AE3br-WZqxxmbGSoEHx48gdCZD';

  return  await callMsGraph(`https://graph.microsoft.com/v1.0/sites/${siteId}/drives/${driveId}/root/children`, {
    method: 'GET',
    header: {
      contentType: 'application/json'
    },
  });
}

export const getCategoriesList = async () => {
  const siteId = '590df659-e094-47f2-9d12-3e91f9d91ba8'
  const listId = '76c6e2b6-feb2-4f3b-bc86-80e0d66f634e'

  return await callMsGraph(`https://graph.microsoft.com/v1.0/sites/${siteId}/lists/${listId}/items?$select=id,fields&$expand=fields($select=Title)`, {
    method: 'GET',
    header: {
      contentType: 'application/json'
    },
  })
}

export const getSasTokenUriForBlob = async (containerName: string, blobName: string, code: string, idToken: string): Promise<any> => {
  try {
    const params = new URLSearchParams({
      containerName,
      blobName,
      code
    });

    const response = await fetch(`${azureFunctionUrl}/api/GetSasTokenUriForBlob?${params.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': '*',
      }
    });
    const urlWithSas = await response.text();
    const [_, sasToken] = urlWithSas.split('?');

    return sasToken;
  } catch (e) {
    console.error('ERROR: ', e);

    throw e;
  }
}

export const getSasTokenUriForTableEntity = async (tableName: string, code: string, rowKey: string, partitionKey: string, idToken: string): Promise<any> => {
  try {
    const params = new URLSearchParams({
      code,
      rowKey,
      tableName,
      partitionKey,
    });

    const response = await fetch(`${azureFunctionUrl}/api/GetSasTokenUriForTableEntity?${params.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': '*',
      }
    });
    const urlWithSas = await response.text();
    const [_, sasToken] = urlWithSas.split('?');

    return sasToken;
  } catch (e) {
    console.error(e);

    throw e;
  }
}

export const getSasTokenUriForQueue = async (code: string, queueName: string, idToken: string): Promise<any> => {
  try {
    const params = new URLSearchParams({
      code,
      queueName,
    });

    const response = await fetch(`${azureFunctionUrl}/api/GetSasTokenUriForQueue?${params.toString()}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Access-Control-Allow-Origin': '*',
      }
    });
    const urlWithSas = await response.text();
    const [_, sasToken] = urlWithSas.split('?');

    return sasToken;
  } catch (e) {
    console.error(e);

    throw e;
  }
}
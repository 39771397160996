// export const getFileDataFromUrl = (url: string) => {
//   return url.includes('http') ? url.substring(url.indexOf('%7B') + 3, url.indexOf('%7D')) : url;
// } 

import {Label} from "../types";

export const getContrastColor = (hexColor: string) => {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? 'black' : 'white';
};

export const getFilesData = (data: any) => {
  const result = data?.value?.reduce((acc: number, curr: any) => {
    return acc ? acc : curr?.hitsContainers.reduce((itemAcc: number, item: any) => itemAcc || item?.hits.reduce((hitAcc: number, hitCurr: any) => {
      if (hitAcc) {
        return hitAcc;
      }
      if (hitCurr?.parentReference?.sharepointIds) {
        const {
          parentReference: {
            siteId, driveId, sharepointIds: { listItemUniqueId }, name,
          }
        } = hitCurr;
        return siteId && driveId && listItemUniqueId && name ? {
          siteId, driveId, listItemUniqueId, name
        } : null;
      }
      return null;
    }));
  });

  return result;
}

export const compareObjects = (first: Object, second: Object) => JSON.stringify(first) === JSON.stringify(second);

export const waitFor = (callback: () => boolean, interval: number = 500): Promise<void> => {
  return new Promise((resolve) => {
    const intervalId = setInterval(() => {
      if (callback()) {
        clearInterval(intervalId);
        resolve();
      }
    }, interval);
  });
}

export const getBlobNameAndSasTokenFromURL = (url: string) => {
  const [blobUrl, sasToken] = url.split('?');
  const parsedBlobUrl = blobUrl.split('/');
  const blobName = parsedBlobUrl[parsedBlobUrl.length - 1];

  return { blobName, sasToken };
}

export const getTextPositions = (text: string, searchText: string) => {
  let start = -1, end = -1;
  const regexp = /(\s)+| |●|	|&|\.|,|-|‎|;/g;
  const formattedContext = searchText.replace(regexp, '');

  // Mapping original text
  for (let i = 0; i < text.length; i++) {
    if (!text[i].match(regexp) && text[i] === formattedContext[0]) {
      let k = 0; // Ignored symbols counter
      let unitedSymbolsCount = 0;
      start = i;

      // Mapping search text that we searching in original text
      for (let j = 0; j < formattedContext.length;) {
        if (!text[i + j + k]) {
          break;
        }

        if (text[i + j + k].match(regexp)) {
          k++
        } else if (text[i + j + k] === formattedContext[j + unitedSymbolsCount]) {
          // Found search text match
          j++;
        } else if (text[i + j + k + 1] === formattedContext[j + unitedSymbolsCount + 2]) {
          j++;
          unitedSymbolsCount++;
        } else {
          start = -1;
          break;
        }
      }

      if (start !== -1) {
        end = formattedContext.length + i + k - unitedSymbolsCount;
        break;
      }
    }
  }

  return { start, end };
}

export const removeDocumentStyles = (htmlString: string) => {
  const doc = new DOMParser().parseFromString(htmlString, 'text/html');

  const styleElements = doc.getElementsByTagName('style');
  for (let i = styleElements.length - 1; i >= 0; i--) {
    styleElements[i].parentNode.removeChild(styleElements[i]);
  }

  const elements = doc.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    elements[i].removeAttribute('style');
  }

  return doc.body.innerHTML;
}
import { type FC, type ChangeEvent, useState } from 'react';
import { Button, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/system';
import type { LabelSelectorProps } from "./interfaces";

const StyledButton = styled(Button)(({theme}) => ({
  color: '#fff',
  width: '100%',
  background: '#02023f',
  '&:hover': {
    background: '#2b2b75',
  }
}));

const LabelSelector: FC<LabelSelectorProps> = ({ categories, onSelect }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLabel = (event.target as HTMLInputElement).value;
    const selected = categories.find(category => category.labelName === selectedLabel);

    setSelectedCategory(selectedLabel);

    if (selected) {
      onSelect(selected);
    }
  };

  const clearSelection = () => {
    setSelectedCategory(null);
    onSelect(null);
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup value={selectedCategory} onChange={handleChange}>
        {categories.map(category => (
          <FormControlLabel
            key={category.categoryId}
            value={category.labelName}
            label={category.labelName}
            control={
              <Radio
                disableRipple
                sx={{
                  color: category.color,
                  '&.Mui-checked': {
                    color: category.color,
                  }
                }}
              />
            }
          />
        ))}
      </RadioGroup>
      <StyledButton onClick={clearSelection}>Clear Selection</StyledButton>
    </FormControl>
  );
};

export default LabelSelector;

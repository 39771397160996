import {Label} from "../../types";

export enum AnswersUploadsSSKeys {
  DOCUMENT_CONTENT = 'ANSWERS_UPLOAD_DOCUMENT_CONTENT'
}

export type SharePointAnswer = Label & {
  title: string
  categories: SharePointCategory[]
  answer: string
  isUploaded: boolean
  isError: boolean
}

export type ValidatedAnswer = {
  Id: string
  Title: string
  Content: string
}

export type SharePointCategory = {
  id: string
  title: string
}
import React, {FC, useState} from "react";
import { styled} from "@mui/system";
import {
  Box,
  Button, Chip,
  Container,
  FormControl,
  IconButton, InputLabel, MenuItem,
  Modal, OutlinedInput, Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { ToolbarAccordion } from "../../components";
import {
  StyledBox,
  StyledHeader,
  StyledText
} from "../../components/AnnotationItem/components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons/faPencil";
import {SharePointAnswer, SharePointCategory, ValidatedAnswer} from "./type";
import {StyledButton} from "../../components/Modal/components";

export const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '90vh',
  margin: '0',
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '100%',
  },
}));

export const StickyDocName = styled(Box)({
  position: 'sticky',
  top: 0,
  width: '100%',
  background: '#FFFFFF',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingLeft: '20px',
  boxSizing: 'border-box',
  zIndex: 5
});

export const DocName = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 'bold'
})

export const TextContainer = styled(Container)(({ theme }) => ({
  width: '65%',
  margin: '10px 0 0',
  overflowY: 'auto',
  height: '100%',
  paddingRight: '10px',
  paddingBottom: '20px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

export const SidebarContainer = styled(Container)(({ theme }) => ({
  maxWidth: '35%',
  background: '#eeeeee',
  padding: '0 3px 10px',
  width: '35%',
  overflowY: 'auto',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    maxWidth: '35%',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

export const StickyToolbarAccordion = styled(ToolbarAccordion)({
  backgroundColor: '#eeeeee',
});

const modalContentContainerStyles = {
  width: 800,
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export const UploadButton = styled(Button)(({theme}) => ({
  color: '#fff',
  width: '100%',
  background: '#02023f',
  '&:hover': {
    background: '#2b2b75',
  }
}));


type AnswerProps = {
  answer: SharePointAnswer
  index: number
  onDelete: (labelId: string) => void
  onEdit: () => void
}

export const Answer: FC<AnswerProps> = ({ answer, index, onDelete, onEdit }) => {
  const isValid = answer.title?.length && answer.categories?.length && answer.answer?.length
  let color = '#FF6666'

  if (answer.isUploaded) {
    color = '#A0E75A'
  } else if (answer.isError) {
    color = '#FF6666'
  } else if (isValid) {
    color = answer.color
  }

  return (
    <StyledBox sx={{ background: color }} id={answer.labelId} key={answer.labelId}>
      <Tooltip title="Edit answer">
        <IconButton onClick={onEdit} sx={{ position: 'absolute', top: '5px', right: '5px' }}>
          <FontAwesomeIcon icon={faPencil} size="xs" color={'rgb(30, 48, 80)'} />
        </IconButton>
      </Tooltip>
      <StyledHeader variant="h6" bgcolor={color}>
        {`Added Answer #${index + 1}`}
      </StyledHeader>
      {answer.title && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <StyledHeader variant="body1" bgcolor={color}>
              Title:
            </StyledHeader>
          </Box>
          <StyledText variant="body1" bgcolor={color}>
            {answer.title}
          </StyledText>
        </Box>
      )}
      {answer.categories && (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <StyledHeader variant="body1" bgcolor={color}>
              Categories:
            </StyledHeader>
          </Box>
          <StyledText variant="body1" bgcolor={color}>
            {answer.categories.map(({ title }) => title).join(', ')}
          </StyledText>
        </Box>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <StyledHeader variant="body1" bgcolor={color}>
          Answer:
        </StyledHeader>
      </Box>
      <StyledText variant="body1" bgcolor={color}>
        {answer.answer || answer.text}
      </StyledText>
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" color="secondary" onClick={() => onDelete(answer.labelId)}>
          Delete
        </Button>
      </Box>
    </StyledBox>
  );
}

type EditAnnotationModalProps = {
  open: boolean
  answer: SharePointAnswer
  categories: SharePointCategory[]
  onClose: () => void
  onSave: (annotation: SharePointAnswer) => void
}

export const EditAnnotationModal = ({ open, answer, categories, onClose, onSave }: EditAnnotationModalProps) => {
  const [title, setTitle] = useState(answer.title ?? '')
  const [selectedCategories, setSelectedCategories] = useState<SharePointCategory[]>(answer.categories || [])
  const [answerText, setAnswerText] = useState(answer.answer || answer.text)

  const isFormValid = title.length && selectedCategories.length && answerText.length

  if (!open) {
    return null;
  }


  const onCategoryChange = (e: any) => {
    const selectedCategories = categories.filter(category => e.target.value.includes(category.id))

    setSelectedCategories(selectedCategories)
  }

  const onSubmit = () => {
    onSave({
      ...answer,
      title,
      categories: selectedCategories,
      answer: answerText
    })
  }

  const handleDelete = (id: string) => (event: any) => {
    const filteredCategories = selectedCategories.filter((category) => category.id !== id)

    setSelectedCategories(filteredCategories)
  };

  const renderValue = (ids: string[]) => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {ids.map((id: string) => {
          const category = categories.find((category) => category.id == id)

          return (
            <Chip
              key={category.title}
              label={category.title}
              onDelete={handleDelete(id)}
              onMouseDown={(e) => e.stopPropagation()}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <Modal open>
      <Container sx={modalContentContainerStyles}>
        <Typography variant="h6" mb="20px" textAlign="center">
          Edit annotation
        </Typography>
        <Box mt="50px" width="100%" display="flex" flexDirection="column" gap="15px">
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel >Select Categories</InputLabel>
            <Select
              multiple
              value={selectedCategories.map(({ id }) => id)}
              onChange={onCategoryChange}
              input={<OutlinedInput label="Select Categories" />}
              renderValue={renderValue}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    overflowY: 'auto'
                  }
                }
              }}
            >
              {categories.map(({ id, title }) => (
                <MenuItem key={id} value={id}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            multiline
            rows={5}
            variant="outlined"
            label="Context"
            value={answerText}
            onChange={e => setAnswerText(e.target.value)}
          />

        </Box>
        <Container sx={{ marginTop: '50px', p: '0 !important', display: 'flex', justifyContent: 'space-between'}}>
          <StyledButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton variant="contained" color="primary" onClick={onSubmit} disabled={!isFormValid}>
            Save
          </StyledButton>
        </Container>
      </Container>
    </Modal>
  )
}


type ValidationModalProps = {
  open: boolean
  answer: SharePointAnswer
  validatedAnswers: ValidatedAnswer[]
  onClose: () => void
  onSave: () => void
}

export const ValidationModal = ({ open, answer, validatedAnswers, onClose, onSave }: ValidationModalProps) => {
  const color = "#FFFFFF"


  return (
    <Modal open>
      <Container sx={{...modalContentContainerStyles, width: '90vw', maxHeight: '80vh'}}>
        <Typography variant="h6" mb="20px" textAlign="center">
          Validate answer
        </Typography>
        <Box mt="50px" width="100%" display="flex" height="100%" sx={{ overflowY: 'auto' }}>
          <Box flex="1">
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <StyledHeader variant="body1" bgcolor={color}>
                  Title:
                </StyledHeader>
              </Box>
              <StyledText variant="body1" bgcolor={color}>
                {answer.title}
              </StyledText>
            </Box>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <StyledHeader variant="body1" bgcolor={color}>
                  Categories:
                </StyledHeader>
              </Box>
              <StyledText variant="body1" bgcolor={color}>
                {answer.categories.map(({ title }) => title).join(', ')}
              </StyledText>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <StyledHeader variant="body1" bgcolor={color}>
                Answer:
              </StyledHeader>
            </Box>
            <StyledText variant="body1" bgcolor={color}>
              {answer.answer}
            </StyledText>
          </Box>
          <Box flex="1" display="flex" flexDirection="column" gap="30px">
            {validatedAnswers.map(({ Title, Content }, idx) => (
              <Box>
                <Typography variant="h5" textAlign="center" mb="15px" mt="25px">Answer № {idx + 1}</Typography>
                <Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <StyledHeader variant="body1" bgcolor={color}>
                      Title:
                    </StyledHeader>
                  </Box>
                  <StyledText variant="body1" bgcolor={color}>
                    {Title}
                  </StyledText>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <StyledHeader variant="body1" bgcolor={color}>
                    Answer:
                  </StyledHeader>
                </Box>
                <StyledText variant="body1" bgcolor={color}>
                  {Content}
                </StyledText>
              </Box>
            ))}
          </Box>
        </Box>
        <Container sx={{ marginTop: '50px', p: '0 !important', display: 'flex', justifyContent: 'space-between'}}>
          <StyledButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton variant="contained" color="primary" onClick={onSave}>
            Save
          </StyledButton>
        </Container>
      </Container>
    </Modal>
  )
}
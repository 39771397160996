import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Label } from '../../types';
import { annotationsKey } from "../../constants";

interface AnnotationState {
  annotations: Label[];
  loadFrom: string;
  questionLoadError: boolean;
}

const initialState: AnnotationState = {
  annotations: [],
  loadFrom: 'server',
  questionLoadError: false,
};

const annotationSlice = createSlice({
  name: 'annotator',
  initialState,
  reducers: {
    setAnnotations(state, action: PayloadAction<Label[]>) {
      const sortedAnnotations = [...action.payload];

      sortedAnnotations.sort((a, b) => a.order - b.order);

      state.annotations = sortedAnnotations;
      sessionStorage.setItem(annotationsKey, JSON.stringify(sortedAnnotations));
    },
    removeAnnotations(state) {
      state.annotations = [];
      sessionStorage.setItem(annotationsKey, JSON.stringify([]));
    },
    updateAnnotation(state, action: PayloadAction<Label>) {
      const filteredAnnotations = state.annotations.filter(item => item.labelId !== action.payload.labelId);
      const newAnnotations = [...filteredAnnotations, action.payload]

      newAnnotations.sort((a, b) => a.order - b.order);

      state.annotations = newAnnotations;
      sessionStorage.setItem(annotationsKey, JSON.stringify(newAnnotations));
    },
    deleteAnnotation(state, action: PayloadAction<string>) {
      const newAnnotations = state.annotations
        .filter(item => item.labelId !== action.payload)
        .map((item, idx) => ({ ...item, order: idx + 1 }));

      state.annotations = [...newAnnotations];
      sessionStorage.setItem(annotationsKey, JSON.stringify(newAnnotations));
    }, 
    setLoadFrom(state, action: PayloadAction<string>) {
      state.loadFrom = action.payload
    },
    setQuestionLoadError(state, action: PayloadAction<boolean>) {
      state.questionLoadError = action.payload
    }
  },
});

export const {
  setAnnotations,
  removeAnnotations,
  deleteAnnotation,
  updateAnnotation,
  setLoadFrom,
  setQuestionLoadError
} = annotationSlice.actions;

export default annotationSlice.reducer;

import {Box, Button, Checkbox, Collapse, IconButton, Link, TextField, Tooltip, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {StyledAnswerText, StyledHeader, StyledSourceBadge, StyledText} from "./components";
import {Answer, Label} from "../../types";
import {FC, useState} from "react";

type Props = {
  annotation: Label
  onEdit: (annotation: Label) => void
}

export const AllAnswers: FC<Props> = ({ annotation, onEdit }) => {
  const [editingAnswer, setEditingAnswer] = useState<Answer | null>(null)
  const [collapsedAnswer, setCollapsedAnswer] = useState<Answer | null>(null)
  const [editInputValue, setEditInputValue] = useState('')


  const onAnswerEdit = (answer: Answer) => {
    setEditingAnswer(answer)
    setEditInputValue(answer.answer.Value)
  }

  const onAnswerSave = (idx: number) => {
    const answers = [...annotation.answers];

    answers[idx] = {
      ...editingAnswer,
      answer: { ...editingAnswer.answer, Value: editInputValue }
    };

    onEdit({ ...annotation, answers });
    setEditingAnswer(null)
    setEditInputValue('')
  }

  const onAnswerSelectedChange = (idx: number, selected: boolean) => {
    const answers = [...annotation.answers];

    answers[idx] = {
      ...answers[idx],
      selected
    }

    onEdit({ ...annotation, answers })
  }


  return (
    <Box>
      {annotation?.answers.map((answer, idx) => {
        const isEditing = answer.answer.Value === editingAnswer?.answer.Value
        const isCollapsed = answer.answer.Value === collapsedAnswer?.answer.Value
        let formattedDate = ''

        if (answer.answer?.LastModified) {
          const date = new Date(answer.answer?.LastModified);

          formattedDate = date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });
        }

        return (
          <Box key={answer.answer.Value}>
            <Typography variant="h5" textAlign="center" mb="15px" mt="25px">Answer № {idx + 1}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Checkbox onChange={() => onAnswerSelectedChange(idx, !answer.selected)} checked={answer.selected} />
                <Typography>Save for final document</Typography>
              </Box>
              <Tooltip title="Edit">
                <IconButton aria-label="edit" onClick={() => onAnswerEdit(answer)} sx={{ position: 'relative', top: '-5px' }}>
                  <FontAwesomeIcon icon={faPenToSquare} size="xs" color={'rgb(30, 48, 80)'} />
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ display: isEditing ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
              <TextField
                fullWidth
                multiline
                rows={10}
                variant="outlined"
                value={editInputValue}
                onChange={(e) => setEditInputValue(e.target.value)} sx={{ bgcolor: '#fff' }}
              />
              <Button
                variant="contained"
                onClick={() => onAnswerSave(idx)}
                sx={{ p: 0, position: 'relative', top: '-2px', maxWidth: '100px', marginTop: '10px' }}
              >
                Save
              </Button>
            </Box>
            <StyledAnswerText variant="body1" bgcolor={annotation.color} sx={{ display: isEditing ? 'none' : 'initial' }}>
              {answer.answer.Value}
            </StyledAnswerText>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: '100%',
                padding: '5px 0',
                position: 'relative',
                marginTop: '50px'
              }}>
              <StyledSourceBadge>{`Source: ${answer.badge}`}</StyledSourceBadge>
              <StyledHeader variant="body1" bgcolor={annotation.color}>
                Answer info:
              </StyledHeader>
              <IconButton onClick={() => setCollapsedAnswer(isCollapsed ? null : answer)}>
                <FontAwesomeIcon size="xs" icon={isCollapsed ? faChevronDown : faChevronUp} />
              </IconButton>
            </Box>
            <Collapse in={isCollapsed}>
              <StyledText variant="body1" bgcolor={annotation.color} >
                {`Last modified: ${formattedDate}`}
              </StyledText>
              {answer.answer.Link ? (
                <StyledText variant="body1" bgcolor={annotation.color} sx={{ wordBreak: 'break-all' }} >
                  <Link href={annotation.answers[idx].answer.Link} target="_blank" rel="noopener noreferrer">{annotation.answers[idx].answer.Link}</Link>
                </StyledText>
              ) : null}
            </Collapse>
          </Box>
        )
      })}
    </Box>
  )
}
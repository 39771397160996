import { type FC } from 'react';
import type { ProgressBarProps } from "./interfaces";
import './style.css'

const ProgressBar: FC<ProgressBarProps> = ({
  text,
  progress,
}) => {
  
  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="progress-filler" style={{ width: `${progress}%` }}></div>
      </div>
      <div>{text}</div>
    </div>
  );
};

export default ProgressBar;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Container,
} from "@mui/material";
import { getAccountInfo, getBlobNameAndSasTokenFromURL, removeDocumentStyles, useAzureGarbageCollector } from "../../utils";
import { msalInstance } from "../../index";
import { v4 as uuidv4 } from "uuid";
import {
  getCategoriesList,
  getSasTokenUriForBlob,
  getSasTokenUriForQueue,
  getSasTokenUriForTableEntity
} from "../../api";
import {
  azureContainerName, azureFunctionBlobCode,
  azureFunctionQueueCode,
  azureFunctionTableCode,
  azureTableName, answersUploadCategories,
  maxAttempts, timeoutDelay
} from "../../constants";
import {
  addEntityToTable,
  addMessageToQueue,
  deleteEntityFromTable,
  downloadBlob,
  getEntityFromTable, uploadDataToBlob
} from "../../api/queueApi";
import {addNewNotification, RootState, setDocumentContent, setDocumentSourceType} from "../../store";
import {LabelSelector, Preloader, TextAnnotator} from "../../components";
import {
  Answer,
  DocName, EditAnnotationModal,
  SidebarContainer,
  StickyDocName,
  StickyToolbarAccordion,
  StyledContainer,
  TextContainer,
  UploadButton, ValidationModal
} from "./components";
import { Category } from "../../types";
import {AnswersUploadsSSKeys, SharePointAnswer, SharePointCategory, ValidatedAnswer} from "./type";

const AnswersUploadPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { addRequestData, deleteTableRequest } = useAzureGarbageCollector();

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [sharePointCategories, setSharepointCategories] = useState<SharePointCategory[]>([])
  const [answers, setAnswers] = useState<SharePointAnswer[]>([])
  const [validatedAnswers, setValidatedAnswers] = useState<Record<string, ValidatedAnswer[]>>({})
  const [editingAnswer, setEditingAnswer] = useState<SharePointAnswer | null>(null)
  const [showValidatedAnswers, setShowValidatedAnswers] = useState<ValidatedAnswer[] | null>(null)
  const [answersForUpload, setAnswersForUpload] = useState<SharePointAnswer[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const docType = useSelector((state: RootState) => state.document.documentSourceType);
  const documentContent = useSelector((state: RootState) => state.document.documentContent);

  const FileName = searchParams.get('FileName');
  const SiteId = searchParams.get('SiteId');
  const DriveId = searchParams.get('DriveId');
  const ItemId = searchParams.get('ItemId');
  const BlobName = searchParams.get('BlobName');
  const DocType = searchParams.get('DocType');

  const isUploadButtonEnabled = answers.length && answers.every(answer => answer.title?.length && answer.categories?.length && answer.answer?.length && !answer.isUploaded)

  useEffect(() => {
    if (DocType !== "undefined") {
      dispatch(setDocumentSourceType(DocType));
    }

    const loadCategories = async () => {
      const res = await getCategoriesList()

      const categories = res.value.map((el: any) => ({
        id: el.id,
        title: el.fields.Title
      }))

      setSharepointCategories(categories)
    }

    loadCategories()

    const documentContent = sessionStorage.getItem(AnswersUploadsSSKeys.DOCUMENT_CONTENT)

    if (documentContent) {
      dispatch(setDocumentContent(documentContent))
    } else {
      getDocumentContent()
    }

  }, [])

  useEffect(() => {
    const keys = Object.keys(validatedAnswers)

    if (!keys.length && answersForUpload.length) {
      uploadAnswersToSharePoint(answersForUpload)

      return
    } else if (!keys.length && !answersForUpload.length) {
      setIsLoading(false)

      return
    } else if (!keys.length) {
      return
    }

    const key = keys.pop()
    const validatedAnswerResult = validatedAnswers[key]

    setShowValidatedAnswers(validatedAnswerResult)

  }, [validatedAnswers])

  const getDocumentContent = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const contentId = uuidv4();
    const contentQueue = 'get-document-content-with-basic-formatting';
    const body = docType === "AzureBlobStorage" ? {
      FileName,
      BlobName,
      DocumentSourceType: docType
    } : {
      FileName,
      SiteId,
      DriveId,
      AccessToken: account.accessToken,
      ItemId,
    }

    const contentTableItem = {
      partitionKey: contentQueue,
      rowKey: contentId,
      Body: JSON.stringify(body),
    }

    const sasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, contentId, contentQueue, account.idToken);

    await addEntityToTable(contentTableItem, sasToken);
    addRequestData('table', { rowKey: contentId, partitionKey: contentQueue, sasToken });

    const contentMessage = {
      Id: ItemId,
      TablePartitionKey: contentQueue,
      TableRowKey: contentId,
    }

    const contentMessageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, contentQueue, account.idToken);

    await addMessageToQueue(contentQueue, JSON.stringify(contentMessage), contentMessageSasToken);


    let attempt = 0;

    while (attempt < maxAttempts) {
      try {
        const docContent = await getEntityFromTable(contentTableItem.partitionKey, contentTableItem.rowKey, sasToken);

        if (docContent === null) {
          return
        }

        if (docContent && docContent.ResponseErrorMessage) {
          console.log(docContent.ResponseStatusCode, docContent.ResponseErrorMessage)
          break;
        }

        if (docContent && docContent.Response) {
          const { blobName, sasToken: blobSasToken } = getBlobNameAndSasTokenFromURL(docContent.Response);
          const documentContent = await downloadBlob(azureContainerName, blobName, blobSasToken);
          const transformedContent = removeDocumentStyles(documentContent);
          // const formattedContent = transformedContent.replace(/●[\r\n]+/g, '');

          dispatch(setDocumentContent(transformedContent));
          sessionStorage.setItem(AnswersUploadsSSKeys.DOCUMENT_CONTENT, transformedContent);
          deleteEntityFromTable(contentTableItem.partitionKey, contentTableItem.rowKey, sasToken);
          deleteTableRequest(contentTableItem.rowKey);
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  const uploadAnswersToSharePoint = async (answersToUpload: SharePointAnswer[]) => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'upload-content-to-sharepoint-answers-site';
    const item = {
      AccessToken: account.accessToken,
      Items: answersToUpload.map(answer => ({
        Id: answer.labelId,
        CategoryIds: answer.categories.map(({ id }) => id),
        Title: answer.title,
        Content: answer.answer
      }))
    }

    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    uploadDataToBlob(azureContainerName, uuid, item, blobSasToken);

    const sasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    const tableItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    }
    await addEntityToTable(tableItem, sasToken);

    const message = {
      Id: ItemId,
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    }
    const messageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    await addMessageToQueue(queueName, JSON.stringify(message), messageSasToken);

    let attempt = 0;
    while (attempt < maxAttempts) {
      try {
        const tableEntity = await getEntityFromTable(tableItem.partitionKey, tableItem.rowKey, sasToken);

        if (tableEntity && tableEntity.Response) {
          const { FailedItemsIds = [] } = JSON.parse(tableEntity.Response)

          const updatedAnswers = answers.map((answer) => {
            const isError = FailedItemsIds.includes(answer.labelId)

            if (isError) {
              return {
                ...answer,
                isError: true
              }
            }

            const isUploaded = !!answersToUpload.find(({ labelId }) => labelId === answer.labelId)

            return {
              ...answer,
              isUploaded
            }
          })

          const notificationMessage = FailedItemsIds?.length
            ? 'Some answers wasn\'t successfully uploaded to the SharePoint knowledge base'
            : 'The answers are successfully uploaded to the SharePoint knowledge base'

          setAnswers(updatedAnswers)
          setAnswersForUpload([])
          setIsLoading(false)
          dispatch(addNewNotification(notificationMessage))
          break;
        }
      } catch (error) {
        setIsLoading(false)
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      setIsLoading(false)
      console.error('Maximum retries reached, no Response found');
    }
  }

  const onStartUpload = async () => {
    const account = await getAccountInfo(msalInstance.getActiveAccount());
    const uuid = uuidv4();
    const queueName = 'validate-content-using-sharepoint-answers-site';
    const item = {
      AccessToken: account.accessToken,
      Items: answers.map(answer => ({
        Id: answer.labelId,
        Title: answer.title,
        Content: answer.answer
      }))
    }

    setIsLoading(true)
    const blobSasToken = await getSasTokenUriForBlob(azureContainerName, uuid, azureFunctionBlobCode, account.idToken);
    uploadDataToBlob(azureContainerName, uuid, item, blobSasToken);

    const sasToken = await getSasTokenUriForTableEntity(azureTableName, azureFunctionTableCode, uuid, queueName, account.idToken);
    const tableItem = {
      partitionKey: queueName,
      rowKey: uuid,
      Body: uuid,
    }
    await addEntityToTable(tableItem, sasToken);

    const message = {
      Id: ItemId,
      TablePartitionKey: queueName,
      TableRowKey: uuid,
    }
    const messageSasToken = await getSasTokenUriForQueue(azureFunctionQueueCode, queueName, account.idToken);
    await addMessageToQueue(queueName, JSON.stringify(message), messageSasToken);

    let attempt = 0;
    while (attempt < maxAttempts) {
      try {
        const tableEntity = await getEntityFromTable(tableItem.partitionKey, tableItem.rowKey, sasToken);

        if (tableEntity && tableEntity.Response) {
          const response = JSON.parse(tableEntity.Response) as ValidatedAnswer[]
          const validatedAnswers = response.reduce((acc: Record<string, ValidatedAnswer[]>, el) => {
            const elements = acc[el.Id]

            return {
              ...acc,
              [el.Id]: elements ? [...elements, el] : [el]
            }
          }, {})

          const answersForUpload = answers.filter((answer) => !validatedAnswers[answer.labelId])

          setAnswersForUpload(answersForUpload)
          setValidatedAnswers(validatedAnswers)
          break;
        }
      } catch (error) {
        console.error('Error retrieving entity from table:', error);
      }
      attempt++;
      await new Promise(resolve => setTimeout(resolve, timeoutDelay));
    }

    if (attempt === maxAttempts) {
      console.error('Maximum retries reached, no Response found');
    }
  }

  const onAnswerUpdate = (answers: SharePointAnswer[]) => {
    answers.sort((a, b) => a.start - b.start)
    answers = answers.map(a => ({ ...a, labelId: uuidv4() }))

    setAnswers(answers)
  }

  const scrollToAnswer = (answer: SharePointAnswer) => {
    const element = document.getElementById(answer.labelId);

    element.scrollIntoView(false);
  }

  const onDelete = (labelId: string) => {
    const filteredAnswers = answers.filter((answer) => answer.labelId !== labelId)

    setAnswers(filteredAnswers)
  }

  const onEditSave = (annotation: SharePointAnswer) => {
    const updatedAnswers = [...answers]
    const idx = updatedAnswers.findIndex(a => a.labelId === annotation.labelId)

    updatedAnswers.splice(idx, 1, annotation)

    setAnswers(updatedAnswers)
    setEditingAnswer(null)
  }

  const onValidationClose = () => {
    const validatedAnswer = showValidatedAnswers[0]
    const newValidatedAnswers = { ...validatedAnswers }

    delete newValidatedAnswers[validatedAnswer.Id]

    setShowValidatedAnswers(null)
    setValidatedAnswers(newValidatedAnswers)
  }

  const onValidationSave = () => {
    const validatedAnswer = showValidatedAnswers[0]
    const answerForUpload = answers.find((answer) => answer.labelId === validatedAnswer.Id)
    const newValidatedAnswers = { ...validatedAnswers }

    delete newValidatedAnswers[validatedAnswer.Id]

    setAnswersForUpload([...answersForUpload, answerForUpload])
    setValidatedAnswers(newValidatedAnswers)
    setShowValidatedAnswers(null)
  }

  if (!documentContent.length) {
    return <Preloader classes={['fullscreen']} />;
  }

  const validatingAnswer = answers.find((answer) => answer.labelId === showValidatedAnswers?.[0].Id)

  return (
    <StyledContainer disableGutters>
      <TextContainer>
        <StickyDocName>
          <DocName>{FileName}</DocName>
        </StickyDocName>
        <Container sx={{ paddingTop: '20px' }}>
          <TextAnnotator
            textDisplay="pre-wrap"
            text={documentContent}
            categories={answersUploadCategories}
            labels={answers}
            selectedCategory={selectedCategory}
            getQuestionFromText={() => null}
            onUpdateLabels={onAnswerUpdate}
            scrollToAnnotation={scrollToAnswer}
          />
        </Container>
      </TextContainer>
      <SidebarContainer disableGutters>
        <UploadButton
          sx={{ mt: '5px' }}
          variant="contained"
          color="primary"
          disabled={!isUploadButtonEnabled || isLoading}
          onClick={onStartUpload}
        >
          Start Uploading Process
          {isLoading && (
            <CircularProgress
              size={20}
              color="inherit"
              sx={{ ml: 2 }}
            />
          )}
        </UploadButton>
        <StickyToolbarAccordion label="Add Labels" >
          <LabelSelector categories={answersUploadCategories} onSelect={setSelectedCategory} />
        </StickyToolbarAccordion>
        <Box>
          {answers.map((answer, idx) => (
            <Answer index={idx} answer={answer}  onDelete={onDelete} onEdit={() => setEditingAnswer(answer)} />
          ))}
        </Box>
      </SidebarContainer>
      {editingAnswer && (
        <EditAnnotationModal
          open
          answer={editingAnswer}
          categories={sharePointCategories}
          onClose={() => setEditingAnswer(null)}
          onSave={onEditSave}
        />
      )}
      {showValidatedAnswers && (
        <ValidationModal
          open
          answer={validatingAnswer}
          validatedAnswers={showValidatedAnswers}
          onClose={onValidationClose}
          onSave={onValidationSave}
        />
      )}
    </StyledContainer>
  )
}



export default AnswersUploadPage
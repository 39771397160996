import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { getContrastColor } from "../../utils";

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
}));

export const StyledHeader = styled(Typography)(({ theme, bgcolor }: { theme: any; bgcolor: string }) => ({
  backgroundColor: bgcolor,
  color: getContrastColor(bgcolor),
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

export const StyledSecondaryHeader = styled(Typography)(({ theme, bgcolor }: { theme: any; bgcolor: string }) => ({
  backgroundColor: bgcolor,
  color: getContrastColor(bgcolor),
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
}));

export const StyledText = styled(Typography)(({ theme, bgcolor }: { theme: any; bgcolor: string }) => ({
  backgroundColor: bgcolor,
  color: getContrastColor(bgcolor),
  whiteSpace: 'pre-wrap',
  marginBottom: theme.spacing(1),
  maxHeight: 'none',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

export const StyledAnswerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative'
}));

export const StyledSourceBadge = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '50px',
  left: '-16px',
  background: 'rgb(7, 26, 67)',
  color: '#FFF',
  padding: '4px 10px',
}));

export const StyledAnswerText = styled(Typography)(({ theme, bgcolor }: { theme: any; bgcolor: string }) => ({
  backgroundColor: bgcolor,
  color: getContrastColor(bgcolor),
  whiteSpace: 'pre-wrap',
  marginBottom: theme.spacing(1),
  maxHeight: 'none',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}));

export { default as FoundAnswers } from './FoundAnswers';
export { default as AnswersBlock } from './AnswersBlock';
export { default as FoundQuestion } from './FoundQuestion';
